<template>
  <div class="p-4 hovering__pad mx-auto" style="max-width: 450px">
    <h1 class="mb-4">Заполнить статистику блогера</h1>
    <div style="background-color: #fffce3; line-height: 22px" class="fs-16 p-3 mb-5">
      Отправьте статистику блогера, на которого только что оставили отзыв и получите бонус - 3
      проверки блогеров на нашем сервисе! Заполните форму ниже, нажмите “Отправить” и дождитесь
      одобрения вашего отзыва. При одобрении отзыва вам будут начислены проверки.
    </div>
    <b-form>
      <b-form-group label="Ник Instagram">
        <b-form-input
          :class="{ error: $v.model.blogger_profile.candidate_for_instaname.$anyError }"
          v-model="model.blogger_profile.candidate_for_instaname"
          >Введите ник</b-form-input
        >
      </b-form-group>
      <b-form-group label="Охваты сторис">
        <coverage-field :validations="$v" v-model="model"></coverage-field>
      </b-form-group>
      <b-form-group label="Охваты постов">
        <coverage-posts-field :validations="$v" v-model="model"></coverage-posts-field>
      </b-form-group>
      <b-form-group label="Процент женщин">
        <women-percent-field :validations="$v" v-model="model"></women-percent-field>
      </b-form-group>
      <b-form-group label="Преобладающие возраста аудитории">
        <div>
          <nested-list-form-container
            ref="audiences"
            v-model="model.blogger_profile.audiences"
            :lengthLimit="2"
            :initialCount="2"
            :formComponent="MainAudienceField"
            :formComponentAttrs="{ disableScreen: true, sizing: [45, 45, 45], class: ['mb-2'] }"
            :templateObject="model.blogger_profile.main_audience"
          ></nested-list-form-container>
          <screenshot-loader
            :class="{ error: $v.model.blogger_profile.main_audience.screens.$anyError }"
            :detectCropped="true"
            v-model="model.blogger_profile.main_audience.screens"
            :uploadUrl="'/instagram/upload_screenshots/'"
          ></screenshot-loader>
        </div>
      </b-form-group>
      <b-form-group label="Преобладающие города аудитории">
        <div>
          <nested-list-form-container
            ref="cities"
            v-model="model.blogger_profile.cities"
            :lengthLimit="2"
            :initialCount="2"
            :formComponent="MainCityField"
            :formComponentAttrs="{ disableScreen: true, sizing: [45, 45, 45], class: ['mb-2'] }"
            :templateObject="model.blogger_profile.main_city"
          ></nested-list-form-container>
          <screenshot-loader
            :class="{ error: $v.model.blogger_profile.main_city.screens.$anyError }"
            :detectCropped="true"
            v-model="model.blogger_profile.main_city.screens"
            :uploadUrl="'/instagram/upload_screenshots/'"
          ></screenshot-loader>
        </div>
      </b-form-group>
      <b-form-group label="Страна аудитории">
        <main-country-field
          ref="mcf"
          v-model="model.blogger_profile.main_country"
        ></main-country-field>
      </b-form-group>
      <p v-if="error" class="fs-12" style="color: red">{{ error }}</p>
      <b-button @click="submit" variant="yellow" class="w-100 ph-42 mt-4"
        >Отправить данные</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import parsers from '@main/func/parsers';
import bloggers from '@main/api/bloggers';
import { required, minLength, minValue, maxValue } from 'vuelidate/lib/validators';
import MainAudienceField from '@main/components/statistics_form/fields/MainAudienceField.vue';
import NestedListFormContainer from '../reusable/functional/NestedListFormContainer.vue';
import MainCityField from './fields/MainCityField.vue';
import CoverageField from './fields/CoverageField.vue';
import CoveragePostsField from './fields/CoveragePostsField.vue';
import MainCountryField from './fields/MainCountryField.vue';
import WomenPercentField from './fields/WomenPercentField.vue';
import ScreenshotLoader from '../reusable/forms/ScreenshotLoader.vue';

export default {
  components: {
    NestedListFormContainer,
    CoverageField,
    CoveragePostsField,
    MainCountryField,
    WomenPercentField,
    ScreenshotLoader,
  },
  props: ['instaname'],
  computed: { ...mapState(['bloggerOptions']) },
  data: () => ({
    error: null,
    MainAudienceField,
    MainCityField,
    model: {
      blogger_profile: {
        candidate_for_instaname: null,
        coverage_min: null,
        coverage_max: null,
        coverage_screens: [],
        coverage_posts_min: null,
        coverage_posts_max: null,
        posts_coverage_screens: [],
        main_audience: { age_group: null, age_group_percent: null, screens: [] },
        main_city: { coverage: null, city: {}, screens: [] },
        main_country: { country: { id: null }, coverage: null },
        audiences: [],
        cities: [],
        females_percent: null,
        women_percent_screens: [],
      },
    },
  }),
  validations: {
    model: {
      blogger_profile: {
        candidate_for_instaname: { required, minLength: minLength(3) },
        coverage_min: { required },
        coverage_max: { required },
        coverage_screens: { required, minLength: minLength(1) },
        coverage_posts_min: { required },
        coverage_posts_max: { required },
        posts_coverage_screens: { required, minLength: minLength(1) },
        main_audience: {
          age_group: {},
          age_group_percent: {},
          screens: { minLength: minLength(1), required },
        },
        main_city: {
          coverage: {},
          city: {},
          screens: { minLength: minLength(1), required },
        },
        main_country: {
          country: { required, notUndef: async (val) => val && val.short_key !== 'NAN' },
          coverage: { required, minValue: minValue(1) },
          screens: { required, minLength: minLength(1) },
        },
        audiences: {
          minLength: minLength(1),
          validRef: () => {
            this.$refs.audiences.$anyError();
          },
        },
        cities: {
          minLength: minLength(1),
          validRef: () => {
            this.$refs.cities.$anyError();
          },
        },
        females_percent: { required },
        women_percent_screens: { required, minLength: minLength(1) },
      },
    },
  },
  methods: {
    async submit() {
      this.$refs.audiences.validate();
      this.$refs.cities.validate();
      this.$refs.mcf.$v.$touch();
      if (this.$v.$anyError) {
        this.error = 'Заполните все поля. Проверьте правильность введенных данных';
        return;
      }
      await bloggers.create(this.model.blogger_profile);
      this.$router.push({ name: 'reviews' });
    },
  },
  mounted() {
    this.model.blogger_profile.candidate_for_instaname = this.instaname;
  },
};
</script>

<style lang="scss" scoped>
::v-deep legend {
  font-size: 16px !important;
}
</style>
